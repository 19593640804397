<template>
    <div>
        <v-row v-if="!permissions.can_download">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Brand Sales Report</p>
                <p class="text-title mb-0">You do not have permission to view brand sales report</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_download">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-form lazy-validation ref="form" v-model="valid">
                        <v-sheet color="white" elevation="0" class="pa-0">
                            <p class="text-h6 mb-4">Brand Promoter Sales Report</p>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-autocomplete dense clearable label="Brand" :items="items" :rules="rules.project" v-model="filters.project"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-dialog persistent ref="startMonthDialog" width="320px" :return-value.sync="filters.start_month" v-model="startMonthModal">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="Start Month" v-bind="attrs" v-on="on" v-model="filters.start_month"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" type="month" min="2021-09" v-model="filters.start_month">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="startMonthModal = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="saveStartMonth">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <v-dialog persistent ref="endMonthDialog" width="320px" :return-value.sync="filters.end_month" v-model="endMonthModal">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field readonly dense label="End Month" v-bind="attrs" v-on="on" v-model="filters.end_month"></v-text-field>
                                        </template>
                                        <v-date-picker scrollable color="primary" type="month" :min="getMinMonth()" :max="getMaxMonth()" v-model="filters.end_month">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="endMonthModal = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.endMonthDialog.save(filters.end_month)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                    <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                                        <v-icon small left>mdi-download</v-icon> Download
                                    </v-btn>
                                    <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                        <v-icon small left>mdi-undo</v-icon> Reset
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">Note:<br>
                    1.Month only support up to 12 months<br>
                    2.End month cannot be earlier than start month<br>
                    </small>
                </v-col>
            </v-row>
        </template>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
    </div>
</template>


<script>

import { monthSelectList, makeYearList } from '@/utils/dates'
import SalesDownloadDialog from './SalesDownloadDialog.vue'
import SimpleActionDialog from '../../../../shared/SimpleActionDialog.vue'

export default {
    name: 'SalesTable',
    components: {
        simpleActionDialog: SimpleActionDialog,
        downloadDialog: SalesDownloadDialog,
    },
    data() {
        return {
            valid: true,
            startMonthModal: null,
            endMonthModal: null,
            items: [],
            filters: {
                project: '',
                year: '',
                start_month: '',
                end_month: ''
            },
            object: {},
            permissions: {
                can_download: false,
            },
            rules: {
                project: [
                    (value) => !!value || 'Brand is required'
                ],
                start_month: [
                    (value) => !!value || 'Start month is required'
                ],
                end_month: [
                    (value) => {
                        if(!value) {
                            return "End month is required"
                        }

                        if(value && this.filters.start_month){
                            const startMonth = new Date(this.filters.start_month + '-01'), endMonth = new Date(this.filters.end_month + '-01')
                            if(startMonth > endMonth){
                                return "End month must greater than start month"
                            }
                        }

                        return true
                    }
                ]
            },
            errors:{}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        },
        years() {
            return makeYearList(2021)
        },
        months() {
            return monthSelectList()
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.$store.dispatch('epanel/report/getReportSalesBrand').then((response) => {
                this.items = response.data.items
                this.permissions = response.data.permissions
            })
        },
        getMinMonth: function() {
            if(this.filters.start_month){
                return this.filters.start_month
            }

            return '2021-09'
        },
        getMaxMonth: function() {
            if(this.filters.start_month){
                var startYear = this.filters.start_month.substring(0,4), startMonth = this.filters.start_month.substring(5,9)
                var endMonth = startMonth === '01'?'12':(parseInt(startMonth) - 1).toString()
                var endYear = startMonth === '01'?startYear:(parseInt(startYear)+1).toString()
                endMonth = endMonth.length === 1?'0' + endMonth:endMonth
                return endYear + '-' + endMonth
            }
        },
        reset: function() {
            this.filters = { year: '', project: '', start_month: '', end_month: '' }
            this.form.resetValidation()
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download && this.form.validate()){
                const object = { project: this.filters.project, start_month: this.filters.start_month + '-01',
                                 end_month: this.filters.end_month + '-01'}
                this.downloadDialog.updateObject(object)
                this.downloadDialog.open()
            }
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
        saveStartMonth: function() {
            this.$refs.startMonthDialog.save(this.filters.start_month)
            this.filters.end_month = ''
        }
    }
}

</script>